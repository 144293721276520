import * as S from "./FooterV2.style";
import { FooterIconSVG } from "./FooterV2.style.svg";
import React from "react";

export const FooterSNS = ({ footerSNSItems, isMobile }) => {
  return (
    <S.FooterSNSWrapper isMobile={isMobile}>
      <S.FooterSNS>
        {footerSNSItems.map((item) => (
          <S.FooterSNSItem key={item.text} href={item.href} target="_blank">
            <FooterIconSVG item={item.img} />
          </S.FooterSNSItem>
        ))}
      </S.FooterSNS>
    </S.FooterSNSWrapper>
  );
};
