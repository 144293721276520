import styled from "@emotion/styled";
import { wBody1, wBody3 } from "@teamsparta/design-system";
import { sendLog } from "../../../businesslogics/logging";

export const MissionAndTip = ({
  enrolled,
  course_id,
  isGovCredit,
  isFreeTip,
}) => {
  const refundLink =
    "https://teamsparta.notion.site/8ff3799cec5448f2a387a9f34d1d1afe?pvs=4";
  const finishLink =
    "https://teamsparta.notion.site/3de44d5503b74722a8f67c8b5ec08647?pvs=4";
  const studyLink =
    "https://spartacodingclub.kr/community/freeboard/certification";

  return (
    <AsideTipBannerWrapper>
      <AsideTipBannerContainer>
        <AsideTipBannerIcon src="/images/roadmap/mission_icon.svg" />
        <TitleContainer
          onClick={() => {
            sendLog(
              "scc_roadmapPage_click_banner",
              {
                ...enrolled,
                banner_text: "미션 참여하기",
                next_page: studyLink,
                details: "kdc 전용 수강 안내사항",
                page_url: window.location.href,
              },
              ""
            );
            window.open(studyLink, "_blank");
          }}
        >
          <AsideTipBannerSubTitle>
            학습 의지를 불태우고 싶다면
          </AsideTipBannerSubTitle>
          <AsideTipBannerTitle>커뮤니티 보러가기</AsideTipBannerTitle>
        </TitleContainer>
        <NextIcon src="/images/roadmap/next_icon_gray.svg" />
      </AsideTipBannerContainer>
      <OneLine />
      <AsideTipBannerContainer>
        <AsideTipBannerIcon src="/images/roadmap/honey_tip.svg" />
        <TitleContainer
          onClick={() => {
            sendLog(
              "scc_roadmapPage_click_banner",
              {
                banner_text:
                  isFreeTip && !isGovCredit ? "완주 꿀팁" : "환급 꿀팁",
                next_page: isFreeTip && !isGovCredit ? finishLink : refundLink,
                details: "완주 꿀팁",
                page_url: window.location.href,
              },
              ""
            );
            window.open(
              isFreeTip && !isGovCredit ? finishLink : refundLink,
              "_blank"
            );
          }}
        >
          <AsideTipBannerSubTitle>
            다양한 학습 기능이 궁금하다면
          </AsideTipBannerSubTitle>
          <AsideTipBannerTitle>
            {isFreeTip && !isGovCredit ? "완주 꿀팁 보기" : "학습 꿀팁 보기"}
          </AsideTipBannerTitle>
        </TitleContainer>
        <NextIcon src="/images/roadmap/next_icon_gray.svg" />
      </AsideTipBannerContainer>
    </AsideTipBannerWrapper>
  );
};

const AsideTipBannerWrapper = styled.div`
  display: flex;
  width: 282px; //수강 기간이랑 겹쳐서 너비 맞춤 -10px
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 24px;
  background: #fff;
  margin-bottom: 18px;
`;

const AsideTipBannerContainer = styled.div`
  display: flex;
  justify-content: normal;
  align-items: center;
  align-self: stretch;
`;

const AsideTipBannerIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  cursor: pointer;
  margin-left: 15px;
  width: 300px;
`;

const AsideTipBannerSubTitle = styled.div`
  ${wBody3};
  color: #81898f;
  text-align: center;
`;

const AsideTipBannerTitle = styled.div`
  ${wBody1};
  color: #3a3e41;
  text-align: center;
`;

const NextIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const OneLine = styled.div`
  height: 1px;
  align-self: stretch;
  background: #d7e0e6;
`;
